<template>
  <div class="Title">
    <div class="Rotations">
        <!-- <el-carousel arrow="always" height="500px">
          <el-carousel-item v-for="(item,index) in Rotation" :key="index">
            <img :src="item.url" alt="">
            
          </el-carousel-item>
        </el-carousel> -->
        <div>
          <img src="../../assets/images/index/index06.png" alt="">
        </div>
    </div>
    <div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: "Title",
  data(){
    return {
      // Rotation: [
      //   {
      //     url:require('')
      //   },
      //    {
      //     url:require('../../assets/images/title/title02.png')
      //   },
      //    {
      //      url:require('../../assets/images/title/title03.png')
      //   }
      // ]
    }
  }
};
</script>

<style scoped>
.Title {
  padding-top: 90px;
  margin-bottom: 10px;
}
.el-carousel__item h3 {
  height: 500px;
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 500px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .Rotations img {
    width: 100%;
    height: 500px;
  }
</style>
