<template>
  <div>
    <NavBer></NavBer>
    <Title></Title>

    <div class="w contents">
      <div class="jianjie">
        公司介绍
      </div>
      <el-row>
        <el-col :span="16">
          <div class="grid-content">
            <el-carousel
              :interval="4000"
              type="card"
              height="350px"
              indicator-position="none"
            >
              <el-carousel-item v-for="(item, index) in Rotation" :key="index">
                <div class="chart">
                  <router-link to="/overview"> <img :src="item.url" alt="" /></router-link>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content">
            <p class="">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 公司成立于2005年，注册资金6600万元。主营业务为：药品、医疗器械、保健食品、消杀产品 批发销售，经营品类8000余种。面向药品批发企业、零售药店、医疗机构开展药械配送业务，销售网络覆盖冀南地区。
            </p>
          </div>
        </el-col>
      </el-row>
      <div class="jianjie">
        公司服务
      </div>
      <el-row>
        <el-col :span="12">
          <div class="grid-content">
            <router-link class="dongtai" to="/dynamic">
            <div>
              <img src="../assets/images/index/index04.jpg" alt="">
            </div>
            </router-link>
          </div>
          
        </el-col>
        <el-col :span="12">
          <div class="grid-content">
            <router-link class="dongtai" to="/dynamic">
            <div>
              <img src="../assets/images/index/index05.jpg" alt="">
            </div>
            </router-link>
          </div>
          
        </el-col>
        <el-col :span="12">
          <div class="grid-content">
            <router-link class="dongtai" to="/dynamic">
            <div>
              <img src="../assets/images/index/index07.jpg" alt="">
            </div>
            </router-link>
          </div>
          
        </el-col>
        <el-col :span="12">
          <div class="grid-content">
            <router-link class="dongtai" to="/dynamic">
            <div>
              <img src="../assets/images/index/index08.jpg" alt="">
            </div>
            </router-link>
          </div>
          
        </el-col>
      </el-row>
      <!-- <div class="jianjie">
        新闻资讯
      </div>
      <el-row>
        <el-col :span="8" class="newss">
          <div class="grid-content">
            <div class="xinwen">
              <img src="../assets/images/news/xinwen01.jpg" alt="">
            </div>
            <div>
              <span>2021-10-10</span>
              <router-link to="/news">开幕仪式</router-link>
            </div>
          </div>
        </el-col>
        <el-col :span="8" class="newss">
          <div class="grid-content">
            <div class="xinwen">
              <img src="../assets/images/news/xinwen02.jpg" alt="">
            </div>
            <div>
              <span>2021-10-10</span>
              <router-link to="/news">梵净山健心欢乐之旅《健心中国行》</router-link>
            </div>
          </div>
        </el-col>
        <el-col :span="8" class="newss">
          <div class="grid-content">
            <div class="xinwen">
              <img src="../assets/images/news/xinwen03.jpg" alt="">
            </div>
            <div>
              <span>2021-10-10</span>
              <router-link to="/news">开拓创新 团结奋进 </router-link>
            </div>
          </div>
        </el-col>
      </el-row> -->
    </div>

    <Footbar></Footbar>
  </div>
</template>

<script>
import NavBer from "../components/nav";
import Title from "../components/title";
import Footbar from "../components/footbar";
export default {
  components: {
    NavBer,
    Title,
    Footbar,
  },
  data() {
    return {
      Rotation: [
        {
          url: require("../assets/images/index/index01.jpg"),
        },
        {
          url: require("../assets/images/index/index02.png"),
        },
        {
          url: require("../assets/images/index/index03.png"),
        }
      ],
    };
  },
};
</script>

<style scoped>
.contents {
  margin-top: 60px;
}
.jianjie {
  width: 100px;
  text-align: center;
  margin: 60px auto 40px;
  font-size: 20px;
  border-bottom: 1px solid #333;
}

.el-row {
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 350px;
  overflow: hidden;
   box-shadow: 0px 27px 46px 0px rgba(0, 0, 0, 0.15);
}
.grid-content p {
   width: 240px;
   margin: 80px auto;
   
   
}
.chart img {
  height: 350px;
  width: 100%;
}
.dongtai img {
  width: 100%;
  height: 400px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 350px;
  margin: 0;
}
.newss {
  padding: 10px;
 
}
.xinwen img {
  width: 100%;
  height: 240px;
  margin-bottom: 20px;  
}
.newss span {

  margin:0 20px 0 20px;
  font-size: 14px;
  color: #666;
}

.newss a:hover {
   color: #02b2b5;
}

</style>
